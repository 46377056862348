/* eslint-disable jsx-a11y/media-has-caption */
import { VideoProviderEnum } from '@utmb/types/enum/VideoProviderEnum';
import { VideoProviderUrlEnum } from '@utmb/types/enum/VideoProviderUrlEnum';
import SliceVideoOnlyView from '@utmb/types/view/SliceVideoOnlyView';
import React, { useEffect, useRef, useState } from 'react';
import Script from 'next/script';

import styles from './video.module.scss';

export const Video = (props: SliceVideoOnlyView) => {
    const { provider, providerCode } = props;

    const videoContainer = useRef<HTMLDivElement>(null);

    const [videoHeight, setVideoHeight] = useState<number>(0);

    useEffect(() => {
        if (videoContainer.current?.offsetWidth) {
            setVideoHeight(videoContainer.current.offsetWidth / (16 / 9));
        }
    }, [videoContainer.current?.offsetWidth]);

    const videoUrl = {
        [VideoProviderEnum.DAILYMOTION]: `${VideoProviderUrlEnum.DAILYMOTION}${providerCode}`,
        [VideoProviderEnum.FACEBOOK]: `${VideoProviderUrlEnum.FACEBOOK}${providerCode}`,
        [VideoProviderEnum.VIMEO]: `${VideoProviderUrlEnum.VIMEO}${providerCode}`,
        [VideoProviderEnum.YOUTUBE]: `${VideoProviderUrlEnum.YOUTUBE}${providerCode}`,
    };

    return (
        <div className={styles.videoContainer} ref={videoContainer} style={{ height: videoHeight }}>
            {videoContainer.current?.offsetWidth &&
                (provider === VideoProviderEnum.FACEBOOK ? (
                    <>
                        <div id="fb-root"></div>
                        <Script async defer src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2" strategy="lazyOnload" />

                        <div className="fb-video" data-href={videoUrl[provider]} data-width={videoContainer.current.offsetWidth} data-show-text="false">
                            <div className="fb-xfbml-parse-ignore">
                                <blockquote cite={videoUrl[provider]}></blockquote>
                            </div>
                        </div>
                    </>
                ) : (
                    <iframe
                        width="100%"
                        height="100%"
                        src={videoUrl[provider]}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                        frameBorder={0}
                        allowFullScreen={true}
                        title={provider}
                    ></iframe>
                ))}
        </div>
    );
};
